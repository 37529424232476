import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { Row, Container, Col } from "react-bootstrap"
import PressRoomMasthead from "../press-room-masthead"

const Slug = () => (
  <Layout hero={<PressRoomMasthead active="press-releases" />}>
    <SEO title="" description="Official SpaceIt Press Release" />
    <Container fluid className="px-0 main">
      <Container className="pt-3">
        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <a href="/p/press-releases">&larr; Back to press releases</a>
            </p>
            <h2 className="my-3">{/*  */}</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">{/*  */}</Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default Slug
